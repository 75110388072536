import Vue from "vue";

const participants = {
    namespaced: true,
    state: {
        allParticipants: {},
        selfEsteemAll: true,
        selfEsteemByPerson: {},
        loadedColleagues: {},
        colleaguesIds: {}, //default on backend if null or undefined, empty if empty array
        subordinatesIds: {},
        headsIds: {} 
    },
    getters: {
        getAllParticipants: state => state.allParticipants,
        getSelfEsteemAll: state => state.selfEsteemAll,
        getSelfEsteemById: state => id => state.selfEsteemByPerson[id],
        getSelfEsteemPersonal: state => state.selfEsteemByPerson,
        getLoadedColleagues: state => id => state.loadedColleagues[id],
        getColleaguesIds: state => id => state.colleaguesIds[id],
        getSubordinatesIds: state => id => state.subordinatesIds[id],
        getHeadsIds: state => id => state.headsIds[id],
    },
    mutations: {
        REMOVE_STRUCTURE_FOR_USER(state, userId) {
            Vue.delete(state.headsIds, userId);
            Vue.delete(state.colleaguesIds, userId);
            Vue.delete(state.subordinatesIds, userId);
        },
        REMOVE_SELF_ESTEEM_BY_PERSON(state, participantId) {
            Vue.delete(state.selfEsteemByPerson, participantId);
        },
        CLEAR_ALL_PARTICIPANTS(state) {
            Vue.set(state, 'allParticipants', {});
        },
        REMOVE_FROM_ALL_PARTICIPANTS(state, participantId) {
            Vue.delete(state.allParticipants, participantId);
        },
        SET_SELF_ESTEEM_PERSONAL_ALL(state, value) {
            Object.keys(state.selfEsteemByPerson).forEach(id => {
                Vue.set(state.selfEsteemByPerson, id, value);
            });
        },
        CLEAR_ALL_STRUCTURED_USERS(state) {
            Vue.set(state, 'colleaguesIds', {});
            Vue.set(state, 'subordinatesIds', {});
            Vue.set(state, 'headsIds', {});
        },
        RESET_SELF_ESTEEM(state) {
            Vue.set(state, 'selfEsteemAll', true);
            Vue.set(state, 'selfEsteemByPerson', {});
        },
        SET_ALL_PARTICIPANTS(state, users) {
            Vue.set(state, 'allParticipants', users);
        },
        SET_HEADS(state, {id, userIds}) {
            Vue.set(state.headsIds, id, userIds);
        },
        SET_SUBORDINATES(state, {id, userIds}) {
            Vue.set(state.subordinatesIds, id, userIds);
        },
        SET_COLLEAGUES(state, {id, userIds}) {
            Vue.set(state.colleaguesIds, id, userIds);
        },
        SET_LOADED_COLLEAGUES(state, {id}) {
            Vue.set(state.loadedColleagues, id, true);
        },
        SET_SELF_ESTEEM_ALL(state, status) {
            Vue.set(state, 'selfEsteemAll', status);
        },
        SET_SELF_ESTEEM_PERSONAL(state, {id, status}) {
            Vue.set(state.selfEsteemByPerson, id, status);
        },
    },
    actions: {
        removeParticipant({commit}, participantId) {
            commit("REMOVE_FROM_ALL_PARTICIPANTS", participantId);
            commit("REMOVE_SELF_ESTEEM_BY_PERSON", participantId);
            commit("REMOVE_STRUCTURE_FOR_USER", participantId);
        },
        clearAllParticipants({commit}) {
            commit("CLEAR_ALL_PARTICIPANTS");
            commit("RESET_SELF_ESTEEM");
            commit("CLEAR_ALL_STRUCTURED_USERS");
        }
    }
};

export default participants;
