export default class ApiRequests {}

ApiRequests.generation = {
    generateMass: ({ client }, data) => client.post("/api/review/admin/generation/mass", data),
    generatePersonal: ({ client }, data) => client.post("/api/review/admin/generation/personal", data),
    generateFromFile: ({ client }, data) => client.post("/api/review/admin/generation/from_file", data),
    check: ({ client }) => client.get("/api/review/admin/generation/check"),
    getLimits: ({ client }) => client.get("/api/review/admin/limit"),
    setLimits: ({ client }, limits) => client.post("/api/review/admin/limit", limits),
    getDates: ({ client }) => client.get("/api/review/admin/dates"),
    addDate: ({ client }, date) => client.post("/api/review/admin/dates/store", date),
    deleteDate: ({ client }, id) => client.delete("/api/review/admin/dates/" + id),
};
ApiRequests.system = {
    getCurrent: ({ client }) => client.get("/api/review/admin/system/current"),
    saveSystem: ({ client }, data) =>
        client.post(
            "/api/review/admin/system/descriptions",
            data.data
        )
};
ApiRequests.forms = {
    allAdmin: ({ client }, data) => client.get("/api/review/admin/forms/all", { params: data }),
    delete: ({ client }, data) => client.delete("/api/review/admin/forms/delete/", { data }),
    restore: ({ client }, data) => client.post("/api/review/admin/forms/restore/", data),
    allUser: ({ client }, id) => client.get("/api/review/forms/user/" + id),
    getForm: ({ client }, id) => client.get("/api/review/form/" + id),
    saveForm: ({ client }, data) => client.post("/api/review/form/" + data.form_id + "/save", data),
    unpublishedForms: ({ client }, data) =>
        client.get("/api/review/forms/unpublished", { params: data }),
    publish: ({ client }, data) => client.post("/api/review/forms/publish/", data),
};
ApiRequests.evaluations = {
    setValue: ({ client }, data) =>
        client.post("/api/review/evaluation/" + data.evaluation_id, data)
};
ApiRequests.reports = {
    byUser: ({ client }, id) => client.get("/api/review/" + id + "/reports"),
    getReport: ({ client }, id) => client.get("/api/review/report/" + id),
    all: ({ client }, data) => client.get("/api/review/admin/reports/all", { params: data }),
    byDepartment: ({ client }, data) => client.get("/api/review/reports/department", { params: data }),
    bestByDepartment: ({ client }, data) => client.get("/api/review/reports/best/department", { params: data }),
    worstByDepartment: ({ client }, data) => client.get("/api/review/reports/worst/department", { params: data }),
    totalValueByDepartment: ({ client }, data) => client.get("/api/review/reports/total-value/department", { params: data }),
    rollBackReport: ({ client }, report_id) => client.post("/api/review/admin/report/rollBack/" + report_id ),
    export: (({ client }, data) => client.post("/api/review/admin/reports/export", data, {responseType: 'blob'})),
    exportReport: (({ client }, data) => client.post("/api/review/admin/report/exportOneUserReport", data, {responseType: 'blob'}))
};

ApiRequests.workspaceParams = {
    saveParameters: ({ client }, data) => client.post("/api/review/admin/workspace/save_parameters", data),
};


/*ApiRequests.bitrix = {
    is_admin: ({ client }) => client.get("/api/v1/worker/bitrix/admin")
};*/

ApiRequests.users = {
    previousReviewList: ({ client }, id) => client.get("/api/admin/previous-review-list/" + id),
    getBitrixId: ({ client }, id) => client.get(`/api/v1/worker/${id}/bitrix_id`)
};

/*ApiRequests.reports = {
    byUser: ({ client }, id) => client.get("/api/v1/" + id + "/reports"),
    all: ({ client }, data, status) => {
        if (String(status) === "admin")
            return client.get("/api/v1/evaluations/all/", { params: data });
        if (String(status) === "head")
            return client.get("/api/v1/evaluations/department/", { params: data });
        return client.get("/api/v1/evaluations/user", { params: data });
    },
    rollBackReport: ({ client }, report_id, user_id) =>
        client.post("/api/v1/report/rollBack/" + report_id + "/" + user_id)
};*/

/*ApiRequests.forms = {

    allUser: ({ client }, id, data) => client.get('/api/v1/forms/' + id + '/all', { params: data }),


    unpublished: ({ client }, data, status) => (String(status) === 'admin')
        ? client.get('/api/admin/forms/unpublished/', { params: data })
        : client.get('/api/v1/forms/unpublished/', { params: data }),
    publish: ({ client }, data) => client.post('/api/v1/forms/publish/', data)
};*/

ApiRequests.notifies = {
    start: ({ client }, data) => client.post("/api/admin/workers/notify/start", data)
};
