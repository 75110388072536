import Vue from "vue";

const generation = {
    namespaced: true,
    state: {
        limitsLoaded: false,
        limitsList: {},
        datesLoaded: false,
        datesList: {}
    },
    getters: {
        limitsLoaded: state => state.limitsLoaded,
        limitsList: state => state.limitsList,
        datesLoaded: state => state.datesLoaded,
        datesList: state => state.datesList
    },
    mutations: {
        SET_LIMITS_LOADED(state, loaded) {
            Vue.set(state, "limitsLoaded", loaded);
        },
        SET_LIMITS(state, list) {
            Vue.set(state, "limitsList", list);
        },
        SET_DATES_LOADED(state, loaded) {
            Vue.set(state, "datesLoaded", loaded);
        },
        SET_DATES(state, list) {
            Vue.set(state, "datesList", list);
        },
    },
    actions: {
        loadLimits(context) {
            let request = this.$app.$api.generation.getLimits();
            request.then(response => {
                if (Object.keys(response.data).length)
                    context.commit("SET_LIMITS", response.data);
                context.commit("SET_LIMITS_LOADED", true);
            });
            return request;
        },
        saveLimits(context, limits) {
            context.commit("SET_LIMITS_LOADED", false);
            let request = this.$app.$api.generation.setLimits(limits.data);
            request.then(response => {
                context.commit("SET_LIMITS", response.data.data);
                context.commit("SET_LIMITS_LOADED", true);
            });
            return request;
        },
        loadDates(context) {
            let request = this.$app.$api.generation.getDates();
            request.then(response => {
                if (Object.keys(response.data).length)
                    context.commit("SET_DATES", response.data);
                context.commit("SET_DATES_LOADED", true);
            });
            return request;
        },
        addDate(context, date) {
            context.commit("SET_DATES_LOADED", false);
            let request = this.$app.$api.generation.addDate(date.data);
            request.then(response => {
                context.commit("SET_DATES", response.data);
                context.commit("SET_DATES_LOADED", true);
            });
            return request;
        },
        deleteDate(context, { id }) {
            context.commit("SET_DATES_LOADED", false);
            let request = this.$app.$api.generation.deleteDate(id);
            request.then(response => {
                context.commit("SET_DATES", response.data);
                context.commit("SET_DATES_LOADED", true);
            });
            return request;
        },
        generateMass({ commit, dispatch }, payload) {
            commit("SET_DATES_LOADED", false);
            let request = this.$app.$api.generation.generateMass(payload.data);
            request.then(() => {
                dispatch("unsetFormsAfterGeneration");
            });
            return request;
        },
        generatePersonal({ commit, dispatch }, payload) {
            commit("SET_DATES_LOADED", false);
            let request = this.$app.$api.generation.generatePersonal(payload.data);
            request.then(() => {
                dispatch("unsetFormsAfterGeneration");
            });
            return request;
        },
        generateFromFile({ commit, dispatch }, payload) {
            commit("SET_DATES_LOADED", false);
            let request = this.$app.$api.generation.generateFromFile(payload);
            request.then(() => {
                dispatch("unsetFormsAfterGeneration");
            });
            return request;
        },
        unsetFormsAfterGeneration({ commit }) {
            commit("forms/SET_FORMS_ADMIN_LOADED", false, { root: true });
            commit("forms/UNSET_FORMS_ADMIN", null, { root: true });
            commit("forms/SET_FORMS_USER_LOADED", false, { root: true });
            commit("forms/UNSET_FORMS_USER", null, { root: true });
        },
        checkVidgets() {
            return this.$app.$api.generation.check();
        },
    }
};

export default generation;
