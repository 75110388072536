export default {
    review: {
        main: "Оценка персонала",
        menu: {
            my_forms: "Анкеты на оценку",
            my_reports: "Мои отчёты",
            head: "Руководитель",
            head_reports: "Отчёты подчинённых",
            department_reports: "Отчёты отделов",
            publish: "Публикация отчётов",
            administration: "Администратор",
            generation: "Запуск оценки",
            admin_forms: "Анкеты",
            admin_reports: "Отчёты",
            help: "Помощь",
            roles: "Роли",
            parameters: "Настройки",
        },
        pages: {
            generation: {
                title: "Запуск анкетирования",
                headers: {
                    members: "Участники",
                    positions: "Должности",
                    departments: "Отделы",
                    workers: "Сотрудники",
                    graded: "Оцениваемый",
                    grading: "Оценивающие",
                    status: "Статус оценивающих",
                    limits: "Установка лимита формирования оценок",
                    auto: "Запланировать запуск",
                    add_date: "Добавить дату",
                    dates: "Даты:",
                    competences: "Посмотреть компетенции и индикаторы",
                    competences_link: 'Перейти в сервис "Управление компетенциями"',
                    system: "Система оценок",
                    file: "Запуск оценки из файла"
                },
                messages: {
                    limits_saved: "Лимиты сохранены",
                    system_saved: "Новая система установлена",
                    descriptions_length: "Пожалуйста, введите строку длиной от 2 до 200 символов",
                    generate_success: "Генерация оценки успешно запущена!",
                    generate_from_file_running: "Запуск оценки из файла запущен. После проверки файла и генерации анкет вам будет направлено уведомление в Новости Битрикс."
                },
                buttons: {
                    system_save: "Сохранить систему оценок",
                    system_edit: "Изменить систему оценок",
                    system_rollback: "Отменить изменения",
                    new_system_set: "Изменить шкалу",
                    set_grade: "Установить",
                    start_now: "Запустить оценку",
                    additionally: "Дополнительно"
                },
                toggle: {
                    global: "Групповая оценка",
                    personal: "Одиночная оценка",
                    file: "Запуск оценки из файла"
                },
                statuses: {
                    auto: "Автоматический",
                    colleague: "Коллега",
                    head: "Руководитель",
                    self: "Самооценка",
                    sub: "Подчинённый"
                },
                vidget_links: {
                    invite: "Пригласить пользователей",
                    departments: "Создать департаменты",
                    positions: "Создать должности",
                    "manage-users": "Привязать пользователей к отделам и должностям",
                    competences: "Создать компетенции и индикаторы",
                    "competences/bind": "Привязать индикаторы",
                },
                wrong_forms_modal: {
                    global_1: "Не удалось сгенерировать анкеты на следующих пользователей:",
                    global_2: "На этих пользователей есть неопубликованные анкеты, которые содержат неактуальную систему оценки.",
                    personal_1: "Не удалось сгенерировать анкеты на пользователя {user}.",
                    personal_2: "На данного пользователя есть неопубликованные анкеты, которые содержат неактуальную систему оценки.",
                    question: "Хотите удалить эти анкеты прямо сейчас и создать новые?",
                    submit: "Да, удалить старые и создать новые",
                    cancel: "Нет, я ещё подумаю",
                },
                placeholders: {
                    search_competence: "Введите компетенцию",
                    select_file: "Выберите или перетащите файл",
                    drop_file: "Перетащите файл"
                },
                questions: {
                    questions: "Вопросы",
                    add_question: "Добавить вопрос",
                    placeholder: "Введите вопрос",
                    validation_error: "Введите более двух символов",
                    modal_error_text: "Один или несколько вопросов не заполнены",
                    modal_error_title: "Есть пустые вопросы"
                }
            },
            anonymous:{
                toggle: {
                    anonym: "Публично",
                    anonymous_evaluation: "Анонимная оценка",
                    open_evaluation: "Открытая оценка",
                }
            },
            adminFormsList: {
                title: "Все анкеты",
                headers: {
                    graded: "Кого оценили",
                    role: 'Роль Оценивающего',
                    grading: "Кто оценил",
                    status: "Статус",
                    updated_at: "Дата обновления"
                },
                toggle: {
                    forms_active: "Активные",
                    forms_deleted: "Удалённые"
                },
                messages: {
                    delete_warning: "Вы уверены, что хотите удалить выделенные анкеты?",
                    deleted_success: "Удаление анкет прошло успешно",
                    recover_warning: "Вы уверены, что хотите восстановить выделенные анкеты?",
                    recover_success: "Восстановление анкет прошло успешно"
                },
                emptyPages: {
                    title: "Оценка ещё не запущена",
                }
            },
            userFormsList: {
                title: "Анкеты на оценку",
                no_review: "Анкет на оценку пока нет"
            },
            formFill: {
                title: "Оценка",
                message_placeholder: "Можете оставить здесь свой комментарий",
                buttons: {
                    finish_later: "Завершить позже",
                    finish: "Завершить анкету"
                },
                messages: {
                    finish_later_warning: "Оценки в анкете будут сохранены. Вы сможете вернуться к заполнению анкеты позже",
                    finish_warning: "Вы уверены, что хотите закончить оценку? Внести изменения будет невозможно.",
                },
                progress: "Прогресс прохождения анкеты",
                progress_span: "всего надо ответить по {amount} индикаторам",
                message: "Комментарий",
                fail_save: "Произошла ошибка при сохранении анкеты",
                dont_know: "не знаю",
                questions_progress_span: "всего надо ответить на {amount} вопросов",
                indicator_comments_progress_span: "всего надо оставить {amount} комментариев"
            },
            publish: {
                title_admin: "Публикация отчётов на сотрудников всех отделов",
                title_head: "Публикация отчётов на сотрудников отделов",
                no_departments: "Нет подчинённых отделов",
                no_workspace_departments: "В данном пространстве ещё не создан ни один отдел",
                headers: {
                    graded: "Кого оценили",
                    colleagues_subs: "Коллеги и подчинённые",
                    heads: "Руководители",
                    self: "Самооценка",
                    created_at: "Дата оценки"
                },
                publish_button: "Опубликовать",
                publish_warn: "Формирование отчёта происходит на основании заполненных анкет на сотрудника. Все незаполненные анкеты на сотрудника будут удалены.",
                publish_success: "Отчёты на выбранных сотрудников успешно сформированы",
            },
            user_reports: {
                title: "Отчёты пользователя: ",
                list_tab: "Список отчётов",
                chart_tab: "Динамика",
                dynamics: "Динамика результатов, %",
                no_reports: "На данного пользователя ещё не сформировано ни одного отчёта",
                total_value: "Средняя оценка по отчёту",
                tooltip: "Результат\n",
                scale: "шкала от {min_value} до {max_value}",
                no_reports_title: "Опубликованных отчётов пока нет",
            },
            reports_list: {
                title_admin: "Отчёты",
                title_head: "Отчёты подчинённых",
                headers: {
                    user_name: "Пользователь",
                    positions: "Должность",
                    departments: "Департамент",
                    value: "Балл по последней оценке",
                    created_at: "Дата последней оценки"
                },
                no_reports: "На сотрудников данного отдела ещё не сформировано ни одного отчёта",
                to_page_button: "К отчётам пользователя",
                roll_back_button: "Откатить отчёт",
                roll_back_warn: "Вы уверены, что хотите откатить отчёт?\nДанный отчёт будет удалён. Анкетам, попавшим в отчёт, будет вновь установлен статус \"Заполнена\".",
                roll_back_error: "Произошла ошибка, откат отчёта не выполнен",
                no_reports_title: "Опубликованных отчётов ещё нет",
                no_reports_title_head: "На сотрудников данного отдела опубликованных отчётов ещё нет",
                no_estimation_title_head: "Для сотрудников данного отдела оценка ещё не запущена",
                modal: {
                    dates: "Даты публикации отчетов"
                },
                dates: "Даты публикации отчетов сотрудников:",
                total_value: "Средняя оценка",
                subheader: "Таблица показывает средние оценки по компетенциям сотрудников от всех групп респондентов в рамках последнего опубликованного отчета в периоде.",
                top: {
                    best: "Лидеры",
                    worst: "Сотрудники с потенциалом развития"
                }
            },
            parameters: {
                title: "Доступ к опубликованным отчетам",
                show_for_all: "Открыть личные отчеты всем сотрудникам",
                show_for_all_description: "Настройка влияет на отображение соответствующих вкладок в сервисе. Пользователи не увидят свои отчеты, динамику результатов и балл по последней оценке.",
                show_for_heads: "Открыть отчеты подчиненных руководителям",
                show_for_heads_description: "Настройка влияет на отображение вкладки “Отчеты подчинённых” у руководителей отделов.",
            }
        },
        report: {
            title: "Отчёт пользователя",
            tabs: {
                full_report: "Результаты оценки",
            },
            card_headers: {
                summary: {
                    competences: {
                        best: "Наиболее развитые компетенции",
                        worst: "Наименее развитые компетенции",
                        underestimated: "Недооценённые возможности",
                        overestimated: "Оптимистичная самооценка",
                        selfRate: "Самооценка",
                        subordinatesRate: "Оценка других",
                        noData: "Данных для данного блока не выявлено",
                    },
                },
                radar: {
                    header: "Радар компетенций",
                    subheader: "Диаграмма показывает оценки по всем компетенциям от всех групп респондентов. Она может выявить расхождения в оценках от разных групп",
                },
                table: {
                    header: "Результаты по компетенциям",
                    subheader: "Подробный результат отчёта в разрезе компетенций и индикаторов от всех групп респондентов. В показателе \"Средний балл\" не учитывается самооценка",
                },
                questions: {
                    header: "Ответы на открытые вопросы",
                    empty: "",
                },
	            messages: {
		            header: "Комментарии к анкетам",
		            empty: "",
	            },
            },
            worker_status: {
                colleagues_rate: "Коллеги",
                heads_rate: "Руководители",
                self_rate: "Самооценка",
                subordinates_rate: "Подчинённые",
                full_rate: "Средний балл"
            },
            table_headers: {
                competence: "Компетенция",
                indicator: "Индикатор",
                rate: "Балл",
                comments_for_indicators: "Комментарии к индикатору"
            }
        },
        form: {
            progress_status: {
                status_completed: "Заполнена",
                status_new: "Новая",
                status_published: "Опубликована",
                status_all: "Все",
                status_fail: "Ошибка"
            },

        },
        system: {
            max_value: "Максимальная оценка",
            min_value: "Минимальная оценка",
            qualitative_scale: "Качественная шкала",
            quantitative_scale: "Числовая шкала",
            add_null: "Добавить нулевое значение",
            add_option: "добавить вариант ответа"
        },
        tooltips: {
            status:
                'Статус оценивающего определяет, в качестве кого оценивающий будет оценивать оцениваемого. Опция «Автоматический» означает, что статус будет выбран на основе иерархического отношения сотрудников в структуре предприятия. Другие опции установят статус оценивающего принудительно.\nПример: оценивающий фактически является коллегой оцениваемого, но при выставленном статусе "Руководитель" его оценка будет учтена как оценка от руководителя.',
            members: "Для запуска анкетирования достаточно запонить одно из полей",
            text: 'При запуске в режиме "Открытая оценка" Администратору доступна выгрузка подробных отчетов с детализацией по оценивающим и открыты имена комментаторов. Для Пользователей и Руководителей оценки и комментарии остаются анонимными'
        },
        export_excel: {
            fail_export: "Не удалось экспортировать в excel",
            export_detailed_report: "Excel"
        },
        launch_forms: {
            anonymous: {
                title: "Анонимность результатов в отчетах",
                show_names: "Открыть имена Оценивающих для Администратора",
                description: "Наша система оценки персонала обеспечивает анонимность результатов для всех сотрудников и руководителей. Однако у Администратора есть возможность просматривать имена респондентов в отчете для более детального анализа результатов."
            },
            intro_text: {
                title: "Вступительное слово для анкеты",
                placeholder: "Введите текст",
                description: "Текст будет использован как введение в анкетах респондентов."
            },
            participants: {
                add_participants: "Добавить оцениваемых",
                choose_participants: "Выберите оцениваемых, затем настройте роли оценивающих",
                self_esteem_all: "Самооценка у всех сотрудников",
                self_esteem: "Самооценка",
            },
            competences: {
                add_competences: "Добавить компетенции",
                choose_competences: "Выберите компетенции для оценки"
            },
            choose_type: {
                launch: "Запустить",
                progressive_title: "Прогрессивный запуск",
                progressive_desc: `
                    <li> Удобный выбор компетенций и индикаторов </li>
                    <li> Гибкая настройка участников и их ролей </li>
                    <li> Открытые вопросы </li>
                    <li> Комментарии к индикаторам </li>
                    <li> Качественная или количественная шкала оценки </li>
                    <li> Вступительное слово для респондентов </li>
                    <li> Подготовлен с помощью экспертов в HR </li>
                `,
                old_title: "Базовый запуск",
                old_desc: `
                    <li> Генерация анкет по методу 360 по должностям и отделам </li>
                    <li> Групповой запуск по привязанным компетенциям </li> 
                    <li> Одиночный запуск по сотруднику с выбором оценивающих </li> 
                    <li> Открытые вопросы </li>
                    <li> Качественная и количественная шкала </li>`
            },
            tabs: {
                participants: "Участники",
                competences: "Компетенции",
                questions: "Открытые вопросы",
                parameters: "Параметры запуска"
            }
        }
    }
};
