export default [
    {
        path: "/",
        alias: "/review",
        redirect: { name: "dashboard" },
        name: "root",
        component: () => import("@/view/layout/Layout"),
        children: [
/*            path: "/review",
    redirect: { name: "review.index" },
name: "dashboard",
    components: {
    //search: () => import("./components/Searchbar"),
default: () => import("./App")
},*/
            {
                path: "",
                name: "review.index",
                component: () => import("./views/Main.vue")
            },
            {
                path: "admin/choose-launch-type",
                name: "admin.choose.launch.type",
                component: () => import("./views/ChooseLaunchingType.vue")
            },
            {
                path: "admin/forms-generate",
                name: "admin.forms.generate",
                component: () => import("./views/LaunchReview.vue")
            },
            {
                path: "admin/forms-generate-old",
                name: "admin.forms.generate.old",
                component: () => import("./views/FormsGenerator.vue")
            },
            {
                path: "admin/forms",
                name: "admin.forms.list",
                component: () => import("./views/AdminFormsList.vue"),
            },
            {
                path: "admin/publish",
                name: "admin.forms.publish",
                component: () => import("./views/Publish.vue"),
            },
            {
                path: "admin/parameters",
                name: "parameters",
                component: () => import("./views/Parameters.vue"),
            },
            {
                path: "head/publish",
                name: "head.forms.publish",
                component: () => import("./views/Publish.vue"),
            },
            {
                path: "admin/reports",
                name: "admin.reports",
                component: () => import("./views/ReportsListAdmin.vue"),
            },
            {
                path: "head/reports",
                name: "head.reports",
                component: () => import("./views/ReportsListHead.vue"),
            },
            {
                path: "department/reports",
                name: "department.reports",
                component: () => import("./views/ReportsListHead.vue"),
            },
            {
                path: "/forms",
                component: () => import("./views/UserFormsList.vue"),
                name: "user.forms.list",
            },
            {
                path: "/form/:id",
                component: () => import("./views/FormFill.vue"),
                name: "form.fill",
                props: true
            },
            {
                path: "/user/:id/reports",
                component: () => import("./views/ReportsListUser"),
                name: "user.reports",
                props: true
            },
            {
                path: "/report/:id",
                component: () => import("./views/ReportView.vue"),
                name: "report",
                props: true
            },
            {
                path: "/help",
                component: () => import("./views/Help.vue"),
                name: "help"
            },



            /*{
            path: '/form/',
            component: () => import('./components/FormList/FormList.vue'),
            name: 'FormList',
            meta: {title: 'message.app.nav.list'}
        },
            {
                path: "/form/:id",
                component: () => import("./components/Form/FormComponent.vue"),
                name: "form.fill",
                props: true
            }
             {
            path: '/form/iterview/:id',
            component: () => import('./components/Form/IntrviewFormComponent.vue'),
            name: 'IntrviewFormComponent',
            props: true
        },

        {
            path: '/report',
            component: () => import('./components/FormList/FormReport.vue'),
            name: 'FormReport',
            meta: {title: 'message.app.nav.report'},
            props: true
        },
        {
            path: '/reports',
            component: () => import('./components/FormList/DepartmentReport.vue'),
            name: 'DepartmentReport',
            meta: {title: 'message.app.nav.head_report'},
            props: true
        },
        {
            path: '/forms/user/:id',
            component: () => import('./views/CurrentReviewUserForms.vue'),
            name: 'CurrentReviewUserForms',
            meta: {title: 'message.admin.publish_table.user_forms'},
            props: true
        },
        {
            path: '/forms/head',
            component: () => import('./components/FormList/DepartmentForms.vue'),
            name: 'DepartmentForms',
            meta: {title: 'message.app.nav.head_forms'},
            props: true
        },

        {
            path: '/roles',
            component: () => import('./components/Admin/ManageUsers.vue'),
            name: 'ManageUsers',
            meta: {title: 'message.admin.users.title'},
            props: true
        },
        {
            path: '/administrator',
            component: () => import('./views/administration/AdministratorNavigation'),
            name: 'AdministratorNavigation',
            meta: {title: 'message.app.nav.admin'},
            props: true
        },
        {
            path: '/mobile/form/:id',
            component: () => import('./components/Mobile/MobileComponent.vue'),
            name: 'MobileComponent',
            props: true
        },
        {
            path: '/mobile/interview/form/:id',
            component: () => import('./components/Mobile/InterviewMobileComponent.vue'),
            name: 'InterviewMobileComponent',
            props: true
        },
        {
            path: '/mobile/instruction',
            component: () => import('./components/Mobile/MobileInstruction.vue'),
            name: 'MobileInstruction',
            props: true
        },
        {
            path: '/mobile/list',
            component: () => import('./components/Mobile/MobileList.vue'),
            name: 'MobileList',
            props: true
        },
        {
            path: '/mobile/interview/list',
            component: () => import('./components/Mobile/InterviewMobileList.vue'),
            name: 'InterviewMobileList',
            props: true
        },
        {
            path: '/mobile/end',
            component: () => import('./components/Mobile/MobileEnd.vue'),
            name: 'MobileEnd',
            props: true
        },
        {
            path: '/mobile/error',
            component: () => import('./components/Mobile/MobileError.vue'),
            name: 'MobileError',
            props: true
        },
            {
                path: "/admin",
                component: () => import("./components/Admin/AdminNav.vue"),
                name: "AdminNav",
                meta: { title: "message.app.nav.admin" },
                props: true,
                children: [
                    {
                        path: "/",
                        component: () => import("./components/Admin/CompetencesMenu.vue"),
                        name: "CompetencesMenu",
                        meta: { title: "message.admin.settings.title" },
                        props: true
                    },
                    {
                        path: "generate",
                        component: () => import("./views/FormsGenerator.vue"),
                        name: "FormsGenerator",
                        meta: { title: "message.admin.generation.title" },
                        props: true
                    },
                    {
                        path: "report",
                        component: () => import("./components/Admin/AdminReport.vue"),
                        name: "AdminReport",
                        meta: { title: "message.admin.allReports.title" },
                        props: true
                    },
                    {
                        path: "forms-list",
                        component: () => import("./views/administration/FormsList.vue"),
                        meta: { title: "message.admin.formList.title" },
                        name: "FormsList"
                    },
                    {
                        path: "publish",
                        component: () => import("./components/Admin/AdminForms.vue"),
                        meta: { title: "message.admin.formList.title" },
                        name: "AdminPublish"
                    },
                    {
                        path: "notifications-about-start",
                        component: () =>
                            import("./views/administration/NotificationsAboutStart"),
                        meta: { title: "message.admin.notifications.title" },
                        name: "NotificationsAboutStart"
                    }
                ]
            }*/


        ]
    }
];
