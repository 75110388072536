export default {
    review: {
        main: "Performance review",
        menu: {
            my_forms: "Questionnaires for rating",
            my_reports: "My reports",
            head: "Head of departments",
            head_reports: "Reports by departments",
            department_reports: "Reports by departments",
            publish: "Publish reports",
            administration: "Administration",
            generation: "Start review",
            admin_forms: "Questionnaires",
            admin_reports: "Reports",
            help: "Help",
            roles: "Roles",
            parameters: "Parameters",
        },
        pages: {
            generation: {
                title: "Launch questioning",
                headers: {
                    members: "Questioning members",
                    positions: "Positions",
                    departments: "Departments",
                    workers: "Employees",
                    graded: "Employee for whom the rating is filled",
                    grading: "The employees who fill the rating",
                    status: "Status of employee who fills the rating",
                    limits: "Setting a limit for the formation of grades",
                    auto: "Automatic start",
                    add_date: "Add date",
                    dates: "Dates:",
                    competences: "Show all competences and indicators",
                    competences_link: "Go to \"Competences\" service",
                    system: "Grading system",
                    file: "Launch from file"
                },
                messages: {
                    limits_saved: "You have set new limits",
                    system_saved: "New system set",
                    descriptions_length: "Please enter value between 2 and 200 characters long",
                    generate_success: "The grade generation is successfully start!",
                    generate_from_file_running: "Questioning from file is started. We will notificate you by Bitrix News after file check and forms generation."
                },
                buttons: {
                    system_edit: "Edit grading system",
                    system_save: "Save grading system",
                    system_rollback: "Cancel changes",
                    new_system_set: "Edit grading scale",
                    set_grade: "Set",
                    start_now: "Launch review",
                    additionally: "Additionally"
                },
                toggle: {
                    global: "Mass questioning",
                    personal: "Personal questioning",
                    file: "Questioning from file"
                },
                statuses: {
                    auto: "Auto",
                    colleague: "Colleague",
                    head: "Head",
                    self: "Self rating",
                    sub: "Subordinate",
                },
                vidget_links: {
                    invite: "Invite users",
                    departments: "Create departments",
                    positions: "Create positions",
                    "manage-users": "Binding users to departments and positions",
                    competences: "Create competences and indicators",
                    "competences/bind": "Bind indicators",
                },
                wrong_forms_modal: {
                    global_1: "Failed to generate questionnaires for users:",
                    global_2: "There are unpublished questionnaires for these users that contain outdated rating system.",
                    personal_1: "Failed to generate questionnaires for {user}.",
                    personal_2: "There are unpublished questionnaires for this user that contain outdated rating system.",
                    question: "Do you want to delete these questionnaires right now and create new ones?",
                    submit: "Yes, delete old and create new",
                    cancel: "Not now",
                },
                placeholders: {
                    search_competence: "Start typing competence",
                    select_file: "Choose a file or drop it here",
                    drop_file: "Drop file here"
                },
                questions: {
                    questions: "Questions",
                    add_question: "Add question",
                    placeholder: "Enter question",
                    validation_error: "Enter more than two characters",
                    modal_error_text: "One or more questions are not filled in",
                    modal_error_title: "There are empty questions"
                }
            },
            anonymous:{
                toggle: {
                    anonym: "Public",
                    anonymous_evaluation: "Anonymous evaluation",
                    open_evaluation: "Open evaluation",
                }
            },
            adminFormsList: {
                title: "All questionnaires",
                headers:{
                    graded: "Employee for whom the rating is filled",
                    role: 'The role of the Evaluator',
                    grading: "The employee who fills the rating",
                    status: "Status",
                    updated_at: "Updated at"
                },
                toggle: {
                    forms_active: "Active",
                    forms_deleted: "Deleted",
                },
                messages: {
                    delete_warning: "Are you sure that you want to delete selected questionnaires?",
                    deleted_success: "The questionnaires are successfully deleted",
                    recover_warning: "Are you sure that you want to recover selected questionnaires?",
                    recover_success: "The recovering of the questionnaires is successfully finished",
                },
                emptyPages: {
                    title: "The assessment has not been launched yet",
                }
            },
            userFormsList: {
                title: "Questionnaires for rating",
                no_review: "There are no assessment questionnaires yet"
            },
            formFill: {
                title: "Review",
                message_placeholder: "Add a comment",
                buttons: {
                    finish_later: "Final rate later",
                    finish: "Final rate"
                },
                messages: {
                    finish_later_warning: "All grades will be saved. You can return at any time to continue filling this form out.",
                    finish_warning: "Are you sure that you want to finish the grade? Make changes will not be possible.",
                },
                progress: "Questionnaire progress",
                progress_span: "total number of indicators - {amount}",
                message: "Comment",
                fail_save: "An error occurred while saving the form",
                dont_know: "don't know",
                questions_progress_span: "total number of questions to answer - {amount}",
                indicator_comments_progress_span: "total {amount} comments need to be left"
            },
            publish: {
                title_admin: "Publish reports for employees of all departments",
                title_head: "Publish reports for employees of departments",
                no_departments: "No departments",
                no_workspace_departments: "No department has been created in this workspace yet",
                headers: {
                    graded: "Employee for whom the rating is filled",
                    colleagues_subs: "Colleagues and subordinates",
                    heads: "Heads",
                    self: "Self rate",
                    created_at: "Creation date"
                },
                publish_button: "Publish",
                publish_warn: "While publishing forms all completed forms will be published and all uncompleted forms will be deleted",
                publish_success: "The publishing of the questionnaires is successfully finished",
            },
            user_reports: {
                title: "Reports of ",
                list_tab: "Reports list",
                chart_tab: "Dynamics",
                dynamics: "Dynamics, %",
                no_reports: "No reports for this user",
                total_value: "Final grade",
                tooltip: "Rate\n",
                scale: "scale of {min_value} to {max_value}",
                no_reports_title: "There are no published reports yet",
            },
            reports_list: {
                title_admin: "Reports",
                headers: {
                    user_name: "Worker",
                    positions: "Position",
                    departments: "Department",
                    value: "Rating by last report",
                    created_at: "Last report's date"
                },
                no_reports: "No reports for this department's workers",
                to_page_button: "To user's reports",
                roll_back_button: "Roll back report",
                roll_back_warn: "Are you sure?\nThis report will be deleted. Forms of this report will get status \"Completed\".",
                roll_back_error: "An error occurred while rolling back the report",
                no_reports_title: "There are no published reports yet",
                no_reports_title_head: "There are no published reports on the employees of this department yet",
                no_estimation_title_head: "For the employees of this department, the evaluation has not yet been launched",
                modal: {
                    dates: "Reports publication dates"
                },
                dates: "Publication dates of employee reports:",
                total_value: "Average rating",
                subheader: "The table shows the average ratings for employee competencies from all groups of respondents as part of the last published report in the period.",
                top: {
                    best: "Leaders",
                    worst: "Employees with development potential"
                }
            },
            parameters: {
                title: "Access to Published Reports",
                show_for_all: "Open Personal Reports to All Employees",
                show_for_all_description: "This setting affects the display of the corresponding tabs in the service. Users will not see their own reports, performance dynamics, and the score of the latest evaluation.",
                show_for_heads: "Open Subordinate Reports to Managers",
                show_for_heads_description: "This setting affects the display of the 'Subordinate Reports' tab for department managers.",
            }
        },
        report: {
            title: "Report",
            tabs: {
                full_report: "Review results",
            },
            card_headers: {
                summary: {
                    competences: {
                        best: "The Most Developed Competencies",
                        worst: "The Least Developed Competencies",
                        underestimated: "Underestimated abilities",
                        overestimated: "Optimistic self-esteem",
                        selfRate: "Self-esteem",
                        subordinatesRate: "Evaluation of others",
                        noData: "There is no data for this block",
                    },
                },
                radar: {
                    header: "Radar Chart",
                    subheader: "The diagram shows ratings for all competencies from all respondent groups. It can highlight discrepancies in ratings from different groups",
                },
                table: {
                    header: "Competency Results",
                    subheader: "Detailed competency and indicator results from all respondent groups. Self rate is not included in the average score indicator",
                },
                questions: "Answers for open questions",
                messages: "Comments from forms"
            },
            worker_status: {
                colleagues_rate: "Colleagues",
                heads_rate: "Heads",
                self_rate: "Self Rate",
                subordinates_rate: "Subordinates",
                full_rate: "Average Rate"
            },
            table_headers: {
                competence: "Competence",
                indicator: "Indicator",
                rate: "Rate",
                comments_for_indicators: "Comments on the indicator"
            }
        },
        form: {
            progress_status: {
                status_completed: "Completed",
                status_new: "New",
                status_published: "Published",
                status_all: "All",
                status_fail: "Fail"
            }
        },
        system: {
            max_value: "Max rate",
            min_value: "Min rate",
            qualitative_scale: "Qualitative scale",
            quantitative_scale: "Quantitative scale",
            add_null: "Add zero option",
            add_option: "add option"
        },
        tooltips: {
            status: "The evaluator's status determines in what capacity the evaluator rates the one who's being evaluated. An \"Auto\" option means that status will be specified based on hierarchial relations between workers in a company's structure. Other options will specify the status forcibly.\nFor example, evaluating person is a colleague of an evaluated person, but if an option \"Head\" is selected, this questionnairy will be applied as a grade from the head.",
            members: "Fill at least one field to launch review",
            text: 'When running in the "Open Evaluation" mode, the Administrator can upload detailed reports with details on the evaluators and the names of commentators are open. For Users and Managers, ratings and comments remain anonymous'


        },
        export_excel: {
            fail_export: "Fail export to excel",
            export_detailed_report: "Excel"
        },
        launch_forms: {
            anonymous: {
                title: "Anonymity of results in reports",
                show_names: "Show the names of Evaluators to the Administrator",
                description: "Our personnel evaluation system ensures the anonymity of results for all employees and managers. However, the Administrator has the ability to view the names of respondents in the report for more detailed analysis of the results."
            },
            intro_text: {
                title: "Introductory Word for the Questionnaire",
                placeholder: "Enter text",
                description: "The text will be used as an introduction in respondents' questionnaires."
            },
            participants: {
                add_participants: "Add participants",
                choose_participants: "Choose participants, then set evaluators' roles",
                self_esteem_all: "Self-esteem of all employees",
                self_esteem: "Self-esteem",
            },
            competences: {
                add_competences: "Add competences",
                choose_competences: "Choose competences for evaluation"
            },
            choose_type: {
                launch: "Launch",
                progressive_title: "Progressive Launch",
                progressive_desc: `
                    <li> Convenient selection of competencies and indicators </li>
                    <li> Flexible configuration of participants and their roles </li>
                    <li> Open questions </li> 
                    <li> Comments on indicators </li>
                    <li> Qualitative or quantitative rating scale </li>
                    <li> Introductory word for respondents </li>
                    <li> Prepared with the help of HR experts </li>
                `,
                old_title: "Basic Launch",
                old_desc: `
                    <li> Generation of questionnaires using the 360 method by positions and departments </li>
                    <li> Group launch by attached competencies </li>
                    <li> Single launch by employee with selection of evaluators </li>
                    <li> Open questions </li>
                    <li> Qualitative and quantitative scale </li>
                `
            },
            tabs: {
                participants: "Participants",
                competences: "Competences",
                questions: "Open questions",
                parameters: "Launch parameters"
            }
        }
    }
};
