import Vue from "vue";

const participantsModalTemp = {
    namespaced: true,
    state: {
        participants: {},
        departments: [],
    },
    getters: {
        participants: state => state.participants,
        getDepartments: state => state.departments
    },
    mutations: {
        CLEAR_DEPARTMENTS(state) {
            Vue.set(state, 'departments', []);
        },
        REMOVE_DEPARTMENT(state, id) {
            Vue.set(state, 'departments', Array.from(state.departments.filter(depId => depId != id)));
        },
        SET_DEPARTMENT(state, id) {
            if(!state.departments.includes(id)) {
                state.departments.push(id);
            }
        },
        SET_PARTICIPANTS(state, data) {
            Vue.set(state, 'participants', data);
        },
        SET_DEPARTMENTS(state, data) {
            Vue.set(state, 'departments', data);
        },
        SET_PARTICIPANT(state, data) {
            Vue.set(state.participants, data.id, data);
        },
        REMOVE_PARTICIPANT(state, id) {
            Vue.delete(state.participants, id);
        },
        CLEAR_PARTICIPANTS(state) {
            Vue.set(state, 'participants', {});
        },
    }
};

export default participantsModalTemp;