import reports from "./reports";
import generation from "./generation";
import forms from "./forms";
import reviewModals from "./reviewModals";
import publish from "./publish";
import competence from "@hihub/vue-hihub-competences/src/store/competence";
import participants from "./participants";
import participantsModalTemp from "./participantsModalTemp";
import competencesModalTemp from "./competencesModalTemp";
import competencesGeneration from "./competencesGeneration";
import generationParameters from "./generationParameters";

export default [
    {
        name: "reports",
        module: reports
    },
    {
        name: "generation",
        module: generation
    },
    {
        name: "forms",
        module: forms
    },
    {
        name: "reviewModals",
        module: reviewModals
    },
    {
        name: "publish",
        module: publish
    },
    {
        name: "competence",
        module: competence
    },
    {
        name: "participants",
        module: participants
    },
    {
        name: "participantsModalTemp",
        module: participantsModalTemp
    },
    {
        name: "competencesModalTemp",
        module: competencesModalTemp
    },
    {
        name: "competencesGeneration",
        module: competencesGeneration
    },
    {
        name: "generationParameters",
        module: generationParameters
    }
];
