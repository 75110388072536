export default {
    subheader: {
        display: false,
        breadcrumbs: {
            display: false,
        }
    },
    content: {
        width: "fluid",
    },
};
