import Vue from "vue";

const publish = {
    namespaced: true,
    state: {
        unpublishedFormsList: {},
    },
    getters: {
        unpublishedFormsList: state => state.unpublishedFormsList,

    },
    mutations: {
        SET_UNPUBLISHED_LINE(state, { id, data }) {
            Vue.set(state.unpublishedFormsList, id, data);
        },
        UNSET_UNPUBLISHED_LINE(state, { id }) {
            Vue.delete(state.unpublishedFormsList, id);
        },
        UNSET_UNPUBLISHED_LINES(state) {
            Object.keys(state.unpublishedFormsList).forEach((key) => {
                Vue.delete(state.unpublishedFormsList, key);
            });
        }
    },
    actions: {
        loadUnpublishedForms({ commit }, { data }) {
            return new Promise((resolve, reject) => {
                this.$app.$api.forms
                    .unpublishedForms(data)
                    .then(response => {
                        commit("UNSET_UNPUBLISHED_LINES");
                        response.data.data.forEach(user => {
                            commit("SET_UNPUBLISHED_LINE", { id: user.id, data: user });
                        });
                        commit("forms/SET_FORMS_ADMIN_LOADED", false, { root: true });
                        commit("forms/UNSET_FORMS_ADMIN", null, { root: true });
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        publishForms(state, users_ids) {
            return new Promise((resolve, reject) => {
                this.$app.$api.forms.publish(users_ids)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
};

export default publish;
