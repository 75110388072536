import Vue from "vue";

const generationParameters = {
    namespaced: true,
    state: {
        isAnonym: true,
        questions: [],
        loadedSystem: false,
        system: {},
        errorsSystem: {},
        introText: ''
    },
    getters: {
        getIsAnonym: state => state.isAnonym,
        getQuestions: state => state.questions,
        isLoadedSystem: state => state.loadedSystem,
        getSystem: state => state.system,
        getIntroText: state => state.introText,
    },
    mutations: {
        SET_INTRO_TEXT(state, text) {
            Vue.set(state, 'introText', text);
        },
        SET_IS_ANONYM(state, isAnonym) {
            Vue.set(state, 'isAnonym', isAnonym);
        },
        RESET_ANONYM(state) {
            Vue.set(state, 'isAnonym', true);
        },
        SET_QUESTION(state, question) {
            if(!state.questions.find(item => item.id == question.id)) {
                state.questions.push(question);
            } else {
                state.questions = state.questions.map(item => {
                    if(item.id == question.id) {
                        item.question = question.question;
                    }
                    return item;
                });
            }
        },
        REMOVE_QUESTION(state, id) {
           state.questions = state.questions.filter(item => item.id !== id);
        },
        RESET_QUESTIONS(state) {
            Vue.set(state, 'questions', []);
        },
        SET_LOADED_SYSTEM(state, loaded) {
            Vue.set(state, "loadedSystem", loaded);
        },
        SET_SYSTEM(state, system) {
            Vue.set(state, "system", system);
        },
        SET_ERROR_SYSTEM(state, errors) {
            if (errors === null || errors === undefined) errors = {};
            state.errorsSystem = errors;
        },
    },
    actions: {
        loadCurrentSystem(context) {
            context.commit("SET_LOADED_SYSTEM", false);
            let request = this.$app.$api.system.getCurrent();
            request.then(response => {
                if (Object.keys(response.data).length) {
                    context.commit("SET_SYSTEM", response.data);
                }
                context.commit("SET_LOADED_SYSTEM", true);
            });
            return request;
        },
        saveSystem(context, payload) {
            context.commit("SET_LOADED_SYSTEM", false);
            let request = this.$app.$api.system.saveSystem(payload.data);
            request.then(response => {
                context.commit("SET_SYSTEM", response.data);
                context.commit("SET_LOADED_SYSTEM", true);
            });
            return request;
        },
    }
};

export default generationParameters;