import Vue from "vue";

const reports = {
    namespaced: true,
    state: {
        list: {
            // report_id: {report}
        },
        reportsByUsers: {
            // user_id_1: [1,5,88]
            // user_id_2: [3,77,100]
        },
        usersReportRows: {
            // user_id_1: {user_data + last_report + departments + positions}
            // user_id_2: {user_data + last_report + departments + positions}
        },
        reportsByDepartments: {
            // department_id_1: {
            //    user_id_1: {user_data + last_report + positions}
            // }
        },
        bestReportsByDepartments: {},
        worstReportsByDepartments: {},
        totalValueByDepartments: {},

        loaded: false
    },
    getters: {
        userReportsLoaded: state => id => state.reportsByUsers[id],
        reportsByUserId: state => user_id => {
            let result = [];
            if (state.reportsByUsers[user_id]) {
                state.reportsByUsers[user_id].forEach(report_id => {
                    result.push(state.list[report_id]);
                });
                return result;
            } else return undefined;
        },
        reportById: state => id => state.list[id],
        allReports: state => state.reportsByUsers,
        loaded: state => state.loaded,
        usersReportRows: state => state.usersReportRows,
        reportsByDepartmentId: state => department_id => state.reportsByDepartments[department_id],
        bestReportsByDepartmentId: state => department_id => state.bestReportsByDepartments[department_id],
        worstReportsByDepartmentId: state => department_id => state.worstReportsByDepartments[department_id],
        totalValueByDepartmentId: state => department_id => state.totalValueByDepartments[department_id]
    },
    mutations: {
        ADD_USER_REPORTS(state, { user_id, reports_array }) {
            Vue.set(state.reportsByUsers, user_id, reports_array);
        },
        SET_REPORT(state, { data, is_full = false }) {
            if (state.list[data.id] && state.list[data.id].is_full)
                return;

            if (data.system) {
                let elem_count = data.system.max_value - data.system.min_value + 1;
                let middle = (elem_count - 1) / 2;
                let low_zone_count = Math.round((elem_count - middle) / 2);
                let middle_zone_count = elem_count - (low_zone_count * 2);
                let middle_zone_begin = data.system.min_value + low_zone_count;
                let high_zone_begin = middle_zone_begin + middle_zone_count;

                data["system"]["middle_zone_begin"] = middle_zone_begin;
                data["system"]["high_zone_begin"] = high_zone_begin;
            }
            data["is_full"] = is_full;

            Vue.set(state.list, data.id, data);
        },
        SET_LOADED(state, loaded) {
            Vue.set(state.loaded, loaded);
        },
        SET_USERS_REPORT_ROW(state, { id, data }) {
            Vue.set(state.usersReportRows, id, data);
        },
        UNSET_USERS_REPORT_ROWS(state) {
            Object.keys(state.usersReportRows).forEach((key) => {
                Vue.delete(state.usersReportRows, key);
            });
        },
        SET_DEPARTMENT_REPORTS(state, { department_id, data }) {
            Vue.set(state.reportsByDepartments, department_id, data);
        },
        SET_DEPARTMENT_BEST_REPORTS(state, { department_id, data }) {
            Vue.set(state.bestReportsByDepartments, department_id, data);
        },
        SET_DEPARTMENT_WORST_REPORTS(state, { department_id, data }) {
            Vue.set(state.worstReportsByDepartments, department_id, data);
        },
        SET_TOTAL_VALUE_REPORTS(state, { department_id, data }) {
            Vue.set(state.totalValueByDepartments, department_id, data);
        }
    },
    actions: {
        loadUserReportsById(context, { user_id }) {
            if (context.getters.userReportsLoaded(user_id) === undefined) {
                return new Promise((resolve, reject) => {
                    this.$app.$api.reports
                        .byUser(user_id)
                        .then(response => {
                            let reports_array = [];
                            response.data.data.forEach(report => {
                                context.commit("SET_REPORT", {data: report});
                                reports_array.push(report.id);
                            });

                            context.commit("ADD_USER_REPORTS", {
                                user_id: user_id,
                                reports_array: reports_array
                            });
                            resolve(context.getters.reportsByUserId(user_id));
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            context.state.loaded = true;
                        });
                });
            } else return context.getters.reportsByUserId(user_id);
        },
        loadReportById(context, { report_id }) {
            if (context.getters.reportById(report_id) === undefined || !context.getters.reportById(report_id).is_full) {
                return new Promise((resolve, reject) => {
                    this.$app.$api.reports
                        .getReport(report_id)
                        .then(response => {
                            context.commit("SET_REPORT", { data: response.data.data, is_full: true });
                            resolve(context.getters.reportById(report_id));
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(() => {
                            context.state.loaded = true;
                        });
                });
            } else return context.getters.reportById(report_id);
        },
        loadUsersReportRows({ commit }, { data }) {
            return new Promise((resolve, reject) => {
                this.$app.$api.reports
                    .all(data)
                    .then(response => {
                        commit("UNSET_USERS_REPORT_ROWS");
                        response.data.data.forEach(user => {
                            commit("SET_USERS_REPORT_ROW", { id: user.id, data: user });
                        });
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        loadReportsByDepartmentId({ commit }, { data }) {
            return new Promise((resolve, reject) => {
                this.$app.$api.reports
                    .byDepartment(data)
                    .then(response => {
                        commit("SET_DEPARTMENT_REPORTS", { department_id: data.department, data: response.data.data });
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        loadBestReportsByDepartmentId({ commit }, { data }) {
            return new Promise((resolve, reject) => {
                this.$app.$api.reports
                    .bestByDepartment(data)
                    .then(response => {
                        commit("SET_DEPARTMENT_BEST_REPORTS", { department_id: data.department, data: response.data.data });
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        loadWorstReportsByDepartmentId({ commit }, { data }) {
            return new Promise((resolve, reject) => {
                this.$app.$api.reports
                    .worstByDepartment(data)
                    .then(response => {
                        commit("SET_DEPARTMENT_WORST_REPORTS", { department_id: data.department, data: response.data.data });
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        loadTotalValueByDepartmentId({ commit }, { data }) {
            return new Promise((resolve, reject) => {
                this.$app.$api.reports
                    .totalValueByDepartment(data)
                    .then(response => {
                        commit("SET_TOTAL_VALUE_REPORTS", { department_id: data.department, data: response.data.data });
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        rollBackReport(context, report_id) {
            return new Promise((resolve, reject) => {
                this.$app.$api.reports
                    .rollBackReport(report_id)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        exportToExcelReports(context, data) {
            return new Promise((resolve, reject) => {
                this.$app.$api.reports
                    .export(data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        exportToExcelReport(context, data) {
            return new Promise((resolve, reject) => {
                this.$app.$api.reports
                    .exportReport(data)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }
};

export default reports;
