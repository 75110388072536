export default function checkMyReportForbidden (user, type) {
    if(user && user.workspace.service_parameters) {
        return user.workspace.service_parameters.reduce((res, item) => {
            if(item.service_code == 'review' && item.parameters.myReportsForbidden) {
                res = item.parameters.myReportsForbidden[type];
                return res;
            }
        }, false);
    } 
    return false;
};