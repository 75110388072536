import Vue from "vue";

const forms = {
    namespaced: true,
    state: {
        formsAdminLoaded: false,
        formsAdminList: {},
        formsUserLoaded: false,
        formsUserList: {},
        formsUserSelf: [],
        formsUserHead: [],
        formsUserColleague: [],
        formsUserSub: [],
        formUserWithValuesById: {},
        indicatorCommentsTemp: {}
    },
    getters: {
        formsAdminLoaded: state => state.formsAdminLoaded,
        formsAdminList: state => state.formsAdminList,
        formsUserLoaded: state => state.formsUserLoaded,
        formsUserList: state => state.formsUserList,
        formsUserByWorkerStatus: state => worker_status => {
            let res = [];
            let idsByStatus =
                worker_status === "self"
                    ? state.formsUserSelf
                    : worker_status === "head"
                    ? state.formsUserHead
                    : worker_status === "colleague"
                    ? state.formsUserColleague
                    : state.formsUserSub;

            idsByStatus.forEach(id => {
                if (state.formsUserList[id])
                    res.push(state.formsUserList[id]);
            });
            return res;
        },
        formUserById: state => id => {
            return state.formsUserList[id];
        },
        formValuesLoaded: state => id => {
            if (state.formsUserList[id])
                return Object.prototype.hasOwnProperty.call(state.formsUserList[id], "values");
            else return false;
        },
        formUserWithValuesById: state => id => state.formUserWithValuesById[id],
        getCommentByForm: state => formId => state.formUserWithValuesById[formId].indicatorComments.reduce((obj, item) => {
            obj[item.indicator_id] = item.text;
            return obj;
        }, {}),
        getCommentByFormAndIndicator: state => (formId, indicatorId) => {
            if(!state.formUserWithValuesById[formId]) return '';
            return state.formUserWithValuesById[formId].indicatorComments.reduce((text, item) => {
                if(item.indicator_id == indicatorId) {
                    text = item.text;
                }
                return text;
            }, '');
        },
        getCommentByFormTemp: state => formId => state.indicatorCommentsTemp[formId],
        getCommentByFormAndIndicatorTemp: state => (formId, indicatorId) => {
            if(!state.indicatorCommentsTemp[formId]) return '';
            return state.indicatorCommentsTemp[formId][indicatorId];
        },

    },
    mutations: {
        CLEAR_TEMP_COMMENTS(state) {
            Vue.set(state, "indicatorCommentsTemp", {});
        },
        SET_COMMENT_TEMP(state, data) {
            if(!Object.prototype.hasOwnProperty.call(state.indicatorCommentsTemp, data.formId)){
                Vue.set(state.indicatorCommentsTemp, data.formId, {});
            }
            Vue.set(state.indicatorCommentsTemp[data.formId], data.indicatorId, data.text);
        },
        SET_COMMENT(state, data) {
            const form = state.formUserWithValuesById[data.formId];
            if(form) {
                //update if exists
                if(form.indicatorComments.some(item => item.form_id == data.formId && item.indicator_id == data.indicatorId)) {
                    form.indicatorComments = form.indicatorComments.map(item => {
                        if(item.form_id == data.formId && item.indicator_id == data.indicatorId) {
                            item.text = data.text;
                        }
                        return item;
                    });
                } else {
                    //add if not exists
                    form.indicatorComments.push({
                        form_id: data.formId,
                        indicator_id: data.indicatorId,
                        text: data.text
                    });
                }
                Vue.set(form, 'indicatorComments', form.indicatorComments);
            }
        },
        SET_FORMS_ADMIN_LOADED(state, loaded) {
            Vue.set(state, "formsAdminLoaded", loaded);
        },
        /*        SET_FORMS_ADMIN(state, list) {
            Vue.set(state, "formsAdminList", list);
        },*/
        SET_FORM_ADMIN(state, { id, data }) {
            Vue.set(state.formsAdminList, id, data);
        },
        UNSET_FORM_ADMIN(state, id) {
            Vue.delete(state.formsAdminList, id);
        },
        UNSET_FORMS_ADMIN(state) {
            Vue.set(state, "formsAdminList", {});
        },
        SET_FORMS_USER_LOADED(state, loaded) {
            Vue.set(state, "formsUserLoaded", loaded);
        },
        SET_FORM_USER(state, { id, data }) {
            Vue.set(state.formsUserList, id, data);
        },
        SET_FORM_USER_WITH_VALUES(state, {id, data}) {
            Vue.set(state.formUserWithValuesById, id, data);
        },
        UNSET_FORM_USER(state, id) {
            Vue.delete(state.formsUserList, id);
        },
        UNSET_FORMS_USER(state) {
            Vue.set(state, "formsUserList", {});
            Vue.set(state, "formsUserSelf", []);
            Vue.set(state, "formsUserHead", []);
            Vue.set(state, "formsUserColleague", []);
            Vue.set(state, "formsUserSub", []);
            Vue.set(state, "formUserWithValuesById", {});
        },
        SET_FORM_USER_SELF(state, form_id) {
            if (!state.formsUserSelf.includes(form_id)) state.formsUserSelf.unshift(form_id);
        },
        SET_FORM_USER_HEAD(state, form_id) {
            if (!state.formsUserHead.includes(form_id)) state.formsUserHead.unshift(form_id);
        },
        SET_FORM_USER_COLLEAGUE(state, form_id) {
            if (!state.formsUserColleague.includes(form_id))
                state.formsUserColleague.unshift(form_id);
        },
        SET_FORM_USER_SUB(state, form_id) {
            if (!state.formsUserSub.includes(form_id)) state.formsUserSub.unshift(form_id);
        },
    },
    actions: {
        setTemporaryCommentsStore({ commit }, form) {
            form.indicatorComments.forEach(comment => {
                commit("SET_COMMENT_TEMP", {
                    formId: form.id,
                    indicatorId: comment.indicator_id,
                    text: comment.text
                });
            });
        },
        calculateEvaluationsEmptyTotal({ state }, {form_id}) {
            const form = state.formUserWithValuesById[form_id];
            if(form) {
                //evaluations empty
                let emptyEval = 0;
                for(let competenceTitle in form.values) {
                    form.values[competenceTitle].forEach(ind => {
                        if (!ind.value) {
                            emptyEval++;
                        } 
                    });
                }

                //questions empty
                let totalQuestions = 0;
                const filledQuestions = form.questions.reduce((sum, question) => {
                    totalQuestions++;
                    if(form.answers.some(answer => answer.question_id == question.id && answer.text && answer.text.trim().length > 0)) {
                        sum++;
                    }
                    return sum;
                }, 0);
                const emptyQuestions = totalQuestions - filledQuestions;
                
                //indicatorComments
                let totalIndicatorCommetsRequired = 0;
                const filledIndicatorCommentsRequired = form.indicatorCommentsParams.filter(param => {
                    if (param.comment_required) {
                        totalIndicatorCommetsRequired++;
                        return form.indicatorComments.some(item => item.indicator_id == param.competence_id && item.text && item.text.trim().length > 0);
                    }
                    return false;
                }).length;
                return emptyEval + emptyQuestions + (totalIndicatorCommetsRequired - filledIndicatorCommentsRequired);
            }
        },
        recalculatePercent({ state, dispatch }, {form_id}) {
            const form = state.formsUserList[form_id];
            if(form) {
                dispatch("calculateEvaluationsEmptyTotal", {form_id: form_id}).then((resp) => {
                    let newEmptyTotal = resp;
                    Vue.set(form, 'empty_evaluationsTotal', newEmptyTotal);
                    let percent = ((form.evaluationsTotal - form.empty_evaluationsTotal)
                        * 100) / (form.evaluationsTotal);
                    Vue.set(form, "percent", percent);
                });
            }
        },
        setAnswers({ state, dispatch }, answersArr = []) {
            if(answersArr.length) {
                const form = state.formUserWithValuesById[answersArr[0].form_id];
                Vue.set(form, "answers", answersArr);
                dispatch("recalculatePercent", { form_id: answersArr[0].form_id });
            }
        },
        setValue({ state, dispatch }, { form_id, competence_title, indicator_key, value }) {
            let form = state.formUserWithValuesById[form_id];
            Vue.set(
                form.values[competence_title][indicator_key],
                "value",
                value
            );
            dispatch("recalculatePercent", { form_id: form_id });
        },
        loadFormsAdmin({ commit }, { data }) {
            commit("SET_FORMS_ADMIN_LOADED", false);
            return new Promise((resolve, reject) => {
                this.$app.$api.forms
                    .allAdmin(data)
                    .then(response => {
                        response.data.data.forEach(form => {
                            commit("SET_FORM_ADMIN", { id: form.id, data: form });
                        });
                        commit("SET_FORMS_ADMIN_LOADED", true);
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        deleteForms({ commit }, to_delete) {
            if (!isNaN(to_delete)) to_delete = [to_delete];
            return this.$app.$api.forms.delete({ forms: to_delete }).then(() => {
                to_delete.forEach(form_id => {
                    commit("UNSET_FORM_ADMIN", form_id);
                });
            });
        },
        restoreForms({ commit }, to_restore) {
            if (!isNaN(to_restore)) to_restore = [to_restore];
            return this.$app.$api.forms.restore({ forms: to_restore }).then(() => {
                to_restore.forEach(form_id => {
                    commit("SET_FORM_ADMIN", form_id);
                });
            });
        },
        loadFormsUser({ commit }, { user_id }) {
            commit("SET_FORMS_USER_LOADED", false);
            return new Promise((resolve, reject) => {
                this.$app.$api.forms
                    .allUser(user_id)
                    .then(response => {
                        response.data.data.forEach(form => {
                            commit("SET_FORM_USER", { id: form.id, data: form });
                            switch (form.grading_status) {
                                case 1:
                                    commit("SET_FORM_USER_SELF", form.id);
                                    break;
                                case 2:
                                    commit("SET_FORM_USER_HEAD", form.id);
                                    break;
                                case 3:
                                    commit("SET_FORM_USER_COLLEAGUE", form.id);
                                    break;
                                case 4:
                                    commit("SET_FORM_USER_SUB", form.id);
                                    break;
                            }
                        });
                        commit("SET_FORMS_USER_LOADED", true);
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        loadFormValues({ commit, dispatch }, { form_id }) {
            return new Promise((resolve, reject) => {
                this.$app.$api.forms
                    .getForm(form_id)
                    .then(response => {
                        commit("SET_FORM_USER_WITH_VALUES", { id: response.data.data.id, data: response.data.data });
                        //set temp comments defaults
                        dispatch("setTemporaryCommentsStore", response.data.data);
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        changeValue(context, payload) {
            let value = payload.data.value;
            return new Promise((resolve, reject) => {
                this.$app.$api.evaluations
                    .setValue(payload.data)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        value = null;
                        reject(error);
                    })
                    .finally(() => {
                        context.dispatch("setValue", {
                            form_id: payload.data.form_id,
                            competence_title: payload.data.competence_title,
                            indicator_key: payload.data.indicator_key,
                            value: value
                        });
                    });
            });
        },
        saveForm({ state, commit, dispatch }, payload) {
            let request = this.$app.$api.forms.saveForm(payload.data);
            return new Promise((resp, reject) => request
                .then(response => {
                    if(response.data.status === 1) {
                        commit("UNSET_FORM_USER", response.data.id);
                    }
                    //set answers
                    const answersArr = response.data.questions.reduce((arr, item) => {
                        item.answers.forEach(answer => {
                            if(answer.form_id == response.data.id) { //it is on backend, but to be sure
                                arr.push(answer);
                            }
                        });
                        return arr;
                    }, []);
                    dispatch("setAnswers", answersArr);
                    //save comments for indicators from temp to constant store
                    for(let formId in state.indicatorCommentsTemp) {
                        for(let indId in state.indicatorCommentsTemp[formId]) {
                            commit("SET_COMMENT", {
                                formId: formId,
                                indicatorId: indId,
                                text: state.indicatorCommentsTemp[formId][indId]
                            });
                        }
                    }
                    dispatch("recalculatePercent", { form_id: response.data.id });
                    resp();
                })
                .catch(error => {
                    reject(error);
                }));
        },
    }
};

export default forms;
