import Vue from "vue";

const competencesGeneration = {
    namespaced: true,
    state: {
        competences: {}, //=indicators + competences: full checked or partly,
        indicatorsCommentsParams: {}, //id: {available: true/false, required: true/false}
    },
    getters: {
        getCompetences: state => state.competences,
        getIndicatorCommentsAvailableById: state => id => state.indicatorsCommentsParams[id].available,
        getIndicatorCommentsRequiredById: state => id => state.indicatorsCommentsParams[id].required,
        getIndicatorCommentsParams: state => state.indicatorsCommentsParams,
    },
    mutations: {
        CLEAR_INDICATORS_COMMENTS(state) {
            Vue.set(state, 'indicatorsCommentsParams', {});
        },
        DELETE_COMMENT_PARAM(state, id) {
            Vue.delete(state.indicatorsCommentsParams, id);
        },
        CLEAR_COMPETENCES(state) {
            Vue.set(state, 'competences', {});
        },
        SET_COMPETENCES(state, data) {
            Vue.set(state, 'competences', data);
        },
        SET_COMMENT_AVAILABLE(state, data) {
            if(!Object.prototype.hasOwnProperty.call(state.indicatorsCommentsParams, data.id)) {
                Vue.set(state.indicatorsCommentsParams, data.id, {});
            }
            Vue.set(state.indicatorsCommentsParams[data.id], 'available', data.val);
            if(!data.val) {
                Vue.set(state.indicatorsCommentsParams[data.id], 'required', false);
            }
        },
        SET_COMMENT_REQUIRED(state, data) {
            if(!Object.prototype.hasOwnProperty.call(state.indicatorsCommentsParams, data.id)) {
                Vue.set(state.indicatorsCommentsParams, data.id, {});
            }
            Vue.set(state.indicatorsCommentsParams[data.id], 'required', data.val);
            if(data.val) {
                Vue.set(state.indicatorsCommentsParams[data.id], 'available', true);
            }
        },
    },
    actions: {
        setCompetences({commit, state}, data) {
            commit("SET_COMPETENCES", data);
            let newIds = [];
            Object.keys(data).forEach(competenceKey => {
                const [id, type] = competenceKey.split("-");
                if(type == "i") {
                    if(!Object.prototype.hasOwnProperty.call(state.indicatorsCommentsParams, id)) {
                        commit("SET_COMMENT_AVAILABLE", {id: id, val: true});
                        commit("SET_COMMENT_REQUIRED", {id: id, val: false});
                    }
                    newIds.push(id);
                }
            });
            Object.keys(state.indicatorsCommentsParams).forEach(indicatorId => {
                if(!newIds.includes(indicatorId)) {
                    commit("DELETE_COMMENT_PARAM", indicatorId);
                }
            });
        },
        clearCompetences({commit}) {
            commit("CLEAR_COMPETENCES");
            commit("CLEAR_INDICATORS_COMMENTS");
        }
    }
};

export default competencesGeneration;