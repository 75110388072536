export default {
    review: {
        main: "Performance review",
        menu: {
            my_forms: "Fragebögen zur Bewertung",
            my_reports: "Meine Ergebnisse",
            head: "Abteilungsleiter",
            head_reports: "Abteilungsberichte",
            department_reports: "Abteilungsberichte",
            publish: "Veröffentlichung",
            administration: "Administration",
            generation: "Start der Bewertung",
            admin_forms: "Fragebögen",
            admin_reports: "Berichte",
            roles: "Rollen",
            parameters: "Parameter",
        },
        pages: {
            generation: {
                title: "Start der Befragung",
                headers: {
                    members: "Fragebogens Teilnehmer",
                    positions: "Positionen",
                    departments: "Abteilungen",
                    workers: "Mitarbeiter",
                    graded: "Person, die eingeschätzt wird",
                    grading: "Die Beurteilers",
                    status: "Status der Beurteiler",
                    limits: "Festlegung eines Limits für die Bildung von Bewertungen",
                    auto: "Automatischer Start",
                    add_date: "Das Datum hinzufügen",
                    dates: "Daten:",
                    competences: "Alle Kompetenzen und Indikatoren anzeigen",
                    competences_link: "Go to \"Competences\" service",
                    system: "Bewertungssystem",
                    file: "Aus Datei ausführen"
                },
                messages: {
                    limits_saved: "Sie haben neue Grenzen gesetzt",
                    system_saved: "Das neue System ist installiert",
                    descriptions_length: "Bitte Wert zwischen 2 und 200 Zeichen eingeben",
                    generate_success: "Bewertung erfolgreich generiert!",
                    generate_from_file_running: "Questioning from file is started. We will notificate you by Bitrix News after file check and forms generation."
                },
                buttons: {
                    system_edit: "Bearbeiten das Bewertungssystem",
                    system_save: "Speichern das Bewertungssystem",
                    system_rollback: "Abbrechen",
                    new_system_set: "Ein neues System installieren",
                    set_grade: "Setzen",
                    start_now: "Die Bewertung durchführen",
                    additionally: "Zusätzlich"
                },
                toggle: {
                    global: "Massen Fragebogen",
                    personal: "Einzeln Fragebogen",
                    file: "Aus Datei ausführen"
                },
                statuses: {
                    auto: "Automatisch",
                    colleague: "Kollege",
                    head: "Vorgesetzte",
                    self: "Selbstbewertung",
                    sub: "Untergebener",
                },
                vidget_links: {
                    invite: "Benutzer einladen",
                    departments: "Abteilungen erstellen",
                    positions: "Positionen erstellen",
                    "manage-users": "Benutzer an die Abteilungen und Positionen binden",
                    competences: "Kompetenzen und Indikatoren erstellen",
                    "competences/bind": "Indikatoren anbinden",
                },
                wrong_forms_modal: {
                    global_1: "Die Fragebögen für die Benutzer: ",
                    global_2: "lassen sich nicht erstellen. Für diese Benutzer gibt es unveröffentlichte Fragebögen, die ein veraltetes Bewertungssystem enthalten.",
                    personal_1: "Die Fragebögen für {user} lassen sich nicht erstellen.",
                    personal_2: "Für diesen Benutzer gibt es unveröffentlichte Fragebögen, die ein veraltetes Bewertungssystem enthalten.",
                    question: "Möchten Sie diese Fragebögen jetzt löschen und neue erstellen?",
                    submit: "Ja, alte löschen und neue erstellen",
                    cancel: "Nicht jetzt",
                },
                placeholders: {
                    search_competence: "Beginnen Sie mit der Schreibkompetenz",
                    select_file: "Wählen Sie eine Datei aus oder legen Sie sie hier ab",
                    drop_file: "Legen Sie die Datei hier ab"
                },
                questions: {
                    questions: "Fragen",
                    add_question: "Frage hinzufügen",
                    placeholder: "Frage eingeben",
                    validation_error: "Geben Sie mehr als zwei Zeichen ein",
                    modal_error_text: "Eine oder mehrere Fragen sind nicht ausgefüllt",
                    modal_error_title: "Es gibt leere Fragen"
                }
            },
            anonymous:{
                toggle: {
                    anonym: "Öffentlich",
                    anonymous_evaluation: "Anonyme Auswertung",
                    open_evaluation: "Offene Evaluation",
                }
            },
            adminFormsList: {
                title: "Alle Fragebögen",
                headers:{
                    graded: "Person, die eingeschätzt wird",
                    role: 'Die Rolle des Gutachters',
                    grading: "Der Beurteiler",
                    status: "Status",
                    updated_at: "Datum der Aktualisierung"
                },
                toggle: {
                    forms_active: "Aktiv",
                    forms_deleted: "Gelöscht",
                },
                messages: {
                    delete_warning: "Möchten Sie die ausgewählten Fragebogen wirklich löschen?",
                    deleted_success: "Das Löschen von Fragebogen war erfolgreich",
                    recover_warning: "Möchten Sie die ausgewählten Fragebogen wirklich wiederherstellen?",
                    recover_success: "Wiederherstellung von Fragebogen war erfolgreich",
                },
                emptyPages: {
                    title: "Die Auswertung hat noch nicht gestartet",
                }
            },
            userFormsList: {
                title: "Fragebögen zur Bewertung",
                no_review: "Es gibt noch keine Fragebögen zur Bewertung"
            },
            formFill: {
                title: "Bewertung",
                message_placeholder: "Fügen Sie einen Kommentar hinzu",
                buttons: {
                    finish_later: "Abschließen später",
                    finish: "Abschließen"
                },
                messages: {
                    finish_later_warning: "All grades will be saved. You can return at any time to continue filling this form out.",
                    finish_warning: "Möchten Sie die Bewertung wirklich abschließen? Änderungen sind nicht möglich.",
                },
                progress: "Fortschritt des Fragebogens",
                progress_span: "Gesamtzahl der Indikatoren - {amount}",
                message: "Kommentar",
                fail_save: "Beim Speichern des Formulars ist ein Fehler aufgetreten",
                dont_know: "weiß nicht",
                questions_progress_span: "Sie müssen {amount} Fragen beantworten",
                indicator_comments_progress_span: "insgesamt müssen {amount} Kommentare hinterlassen werden"
            },
            publish: {
                title_admin: "Die Veröffentlichung der Fragebögen für Mitarbeiter aller Abteilungen",
                title_head: "Die Veröffentlichung der Fragebögen für Abteilungsmitarbeiter",
                no_departments: "Keine Abteilungen",
                no_workspace_departments: "Keine Abteilungen",
                headers: {
                    graded: "Person, die eingeschätzt wird",
                    colleagues_subs: "Kollegen und Untergebene",
                    heads: "Führungskräfte",
                    self: "Selbstbewertung",
                    created_at: "Datum der Bewertung"
                },
                publish_button: "Veröffentlichen",
                publish_warn: "Beim Veröffentlichen von Fragebögen werden alle ausgefüllten Formulare veröffentlicht und alle leeren Formulare gelöscht.",
                publish_success: "Die Veröffentlichung der Fragebogen war erfolgreich.",
            },
            user_reports: {
                title: "Die Berichte für ",
                list_tab: "Berichtsliste",
                chart_tab: "Dynamik",
                dynamics: "Dynamik, %",
                no_reports: "Keine Berichte für diese Person",
                total_value: "Abschließende Bewertung",
                tooltip: "Bewertung\n",
                scale: "Scala von {min_value} bis {max_value}",
                no_reports_title: "Es gibt noch keine veröffentlichten Berichte",
            },
            reports_list: {
                title_admin: "Die Berichte",
                headers: {
                    user_name: "Mitarbeiter",
                    positions: "Position",
                    departments: "Abteilung",
                    value: "Bewertung nach letztem Bericht",
                    created_at: "Datum des letzten Berichts"
                },
                no_reports: "Keine Berichte",
                to_page_button: "To user's reports",
                roll_back_button: "Roll back report",
                roll_back_warn: "Are you sure?\nThis report will be deleted. Forms of this report will get status \"Completed\".",
                roll_back_error: "An error occurred while rolling back the report",
                no_reports_title: "Es gibt noch keine veröffentlichten Berichte",
                no_reports_title_head: "Es gibt noch keine veröffentlichten Berichte für die Mitarbeiter dieser Abteilung",
                no_estimation_title_head: "Für die Mitarbeiter dieser Abteilung hat die Auswertung noch nicht gestartet",
                modal: {
                    dates: "Veröffentlichungstermine melden"
                },
                dates: "Veröffentlichungstermine der Mitarbeiterberichte:",
                total_value: "Durchschnitt",
                subheader: "Die Tabelle zeigt die durchschnittlichen Bewertungen der Mitarbeiterkompetenzen aller Befragtengruppen im Rahmen des letzten veröffentlichten Berichts im Berichtszeitraum",
                top: {
                    best: "Führungskräfte",
                    worst: "Mitarbeiter mit Entwicklungspotenzial"
                }
            },
            parameters: {
                title: "Zugriff auf veröffentlichte Berichte",
                show_for_all: "Persönliche Berichte für alle Mitarbeiter öffnen",
                show_for_all_description: "Diese Einstellung beeinflusst die Anzeige der entsprechenden Tabs im Service. Benutzer sehen ihre eigenen Berichte, die Leistungsergebnisse und die Bewertung der letzten Bewertung nicht.",
                show_for_heads: "Berichte der Untergebenen für Manager öffnen",
                show_for_heads_description: "Diese Einstellung beeinflusst die Anzeige des Tabs 'Berichte der Untergebenen' für Abteilungsleiter.",
            }
        },
        report: {
            title: "Bericht",
            tabs: {
                full_report: "Bericht",
            },
            card_headers: {
                summary: {
                    competences: {
                        best: "Die am meisten entwickelten Kompetenzen",
                        worst: "Die am wenigsten entwickelten Kompetenzen",
                        underestimated: "Unterschätzte Fähigkeiten",
                        overestimated: "Optimistisches Selbstwertgefühl",
                        selfRate: "Selbstachtung",
                        subordinatesRate: "Bewertung anderer",
                        noData: "Für diesen Block sind keine Daten vorhanden",
                    },
                },
                radar: {
                    header: "Radardiagramm",
                    subheader: "Das Diagramm zeigt Bewertungen zu allen Kompetenzen von allen Befragtengruppen. Es kann Unterschiede in den Bewertungen verschiedener Gruppen aufzeigen",
                },
                table: {
                    header: "Ergebnisse nach Kompetenzen",
                    subheader: "Detaillierte Ergebnisse des Berichts nach Kompetenzen und Indikatoren aller Befragtengruppen. Die Selbstbewertung wird im Durchschnittswert nicht berücksichtigt",
                },
                questions: "Antworten auf offene Fragen",
                messages: "Kommentare"
            },
            worker_status: {
                colleagues_rate: "Kollegen",
                heads_rate: "Führungskräfte",
                self_rate: "Selbstbewertung",
                subordinates_rate: "Untergeordnete",
                full_rate: "Alle"
            },
            table_headers: {
                competence: "Kompetenz",
                indicator: "Indikator",
                rate: "Bewertung",
                comments_for_indicators: "Kommentare zum Indikator"
            }
        },
        form: {
            progress_status: {
                status_completed: "Ausgefüllt",
                status_new: "Neu",
                status_published: "Veröffentlicht",
                status_all: "Alle",
                status_fail: "Fehler"
            }
        },
        system: {
            max_value: "Die Maximale Bewertung",
            min_value: "Die Minimale Bewertung",
            qualitative_scale: "Qualitative Skala",
            quantitative_scale: "Quantitative Skala",
            add_null: "Nulloption hinzufügen",
            add_option: "Option hinzufügen"
        },
        tooltips: {
            status: "Der Status des Bewertenden bestimmt, wie der Bewertende den Bewerteten bewertet. Die Option \"Automatisch\" bedeutet, dass der Status basierend auf der hierarchischen Beziehung der Mitarbeiter in der Unternehmensstruktur ausgewählt wird. Andere Optionen setzen den Status des Auswerters erzwingen.\nZum Beispiel: der Bewertende ist tatsächlich ein Kollege des Bewerteten, aber mit dem Status \"Leiter\" wird seine Bewertung als Bewertung vom Leiter berücksichtigt.",
            members: "Fill at least one field to launch review",
            text: 'Wenn Sie im Modus "Offene Bewertung" ausgeführt werden, können Sie dem Administrator detaillierte Berichte mit detaillierten Bewertungsberichten hochladen und die Namen der Kommentatoren sind offen. Für Benutzer und Führungskräfte bleiben Bewertungen und Kommentare anonym'

        },
        export_excel: {
            fail_export: "Fail export to excel",
            export_detailed_report: "Excel"
        },
        launch_forms: {
            anonymous: {
                title: "Anonymität der Ergebnisse in Berichten",
                show_names: "Namen der Bewertenden für den Administrator anzeigen",
                description: "Unser Personalbewertungssystem gewährleistet die Anonymität der Ergebnisse für alle Mitarbeiter und Führungskräfte. Der Administrator hat jedoch die Möglichkeit, die Namen der Befragten im Bericht für eine detailliertere Analyse der Ergebnisse einzusehen."
            },
            intro_text: {
                title: "Einleitendes Wort für den Fragebogen",
                placeholder: "Text eingeben",
                description: "Der Text wird als Einführung in den Fragebögen der Befragten verwendet."
            },
            participants: {
                add_participants: "Teilnehmende hinzufügen",
                choose_participants: "Wählen Sie Teilnehmende aus und legen Sie dann die Rollen der Bewertenden fest",
                self_esteem_all: "Selbstwertgefühl aller Mitarbeiter",
                self_esteem: "Selbstwertgefühl",
            },
            competences: {
                add_competences: "Kompetenzen hinzufügen",
                choose_competences: "Wählen Sie Kompetenzen zur Bewertung"
            },
            choose_type: {
                launch: "Starten",
                progressive_title: "Progressiver Start",
                progressive_desc: `
                    <li> Bequeme Auswahl von Kompetenzen und Indikatoren </li>
                    <li> Flexible Konfiguration von Teilnehmern und deren Rollen </li>
                    <li> Offene Fragen </li>
                    <li> Kommentare zu Indikatoren </li>
                    <li> Qualitative oder quantitative Bewertungsskala </li>
                    <li> Einleitende Worte für Befragte </li>
                    <li> Vorbereitet mit Hilfe von HR-Experten </li>
                `,
                old_title: "Basisstart",
                old_desc: `
                    <li> Erstellung von Fragebögen nach der 360-Grad-Methode nach Positionen und Abteilungen </li>
                    <li> Gruppenstart nach zugeordneten Kompetenzen </li>
                    <li> Einzelstart nach Mitarbeiter mit Auswahl der Bewerter </li>
                    <li> Offene Fragen </li>
                    <li> Qualitative und quantitative Skala </li>
                `
            }, 
            tabs: {
                participants: "Teilnehmende",
                competences: "Kompetenzen",
                questions: "Offene Fragen",
                parameters: "Startparameter"
            }
        }
    }
};
