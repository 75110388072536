import Vue from "vue";

const competencesModalTemp = {
    namespaced: true,
    state: {
        competences: {}, //=indicators + competences {id: isCheckedFull or partly}
    },
    getters: {
        getCompetences: state => state.competences,
    },
    mutations: {
        SET_COMPETENCES(state, data) {
            Vue.set(state, 'competences', data);
        },
        CLEAR_COMPETENCES(state) {
            Vue.set(state, 'competences', {});
        },
        SET_COMPETENCE(state, {competenceKey, fullChecked}) {
            Vue.set(state.competences, competenceKey, fullChecked);
        },
        REMOVE_COMPETENCE(state, dataKey) {
            Vue.delete(state.competences, dataKey);
        },
    }
};

export default competencesModalTemp;