import Vue from "vue";

const reviewModals = {
    namespaced: true,
    state: {
        save_form: false,


    },
    getters: {
        save_form: state => state.save_form,

    },
    mutations: {
        SET_MODAL(state, modal) {
            Vue.set(state, modal.name, modal.show);
        }
    },
    actions: {


    }
};

export default reviewModals;
